/// ScrollBar Right
body {
  scrollbar-width: thin;          /* "auto" or "thin" */
  scrollbar-color: #898989 #cfcfcf;   /* scroll thumb and track */
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #898989 #cfcfcf;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
  max-height: 200px;
}

*::-webkit-scrollbar-track {
  background: #cfcfcf;
  display: none;
  
  &:hover {
    display: initial;
  }
}

*::-webkit-scrollbar-thumb {
  background-color: #898989;
  border-radius: 20px;
  border: 3px solid #cfcfcf;
  display: none;

  &:hover {
    display: initial;
  }
}

.w100 {
  width: 100%;
}

h4, h5 {
  &.card-title {
    color: $primary;    
    font-weight: bold;
  }
}